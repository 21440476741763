import { useEffect, useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Box, Modal } from "@mui/material";
import { NotificationManager } from "react-notifications";
import { verifyHCaptcha } from "services";
import { analytics } from "analytics/analytics";

export const VerifyHumanModal = (props) => {
  const [token, setToken] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const captchaRef = props.verifyHumanModalRef;

  //   console.log("VerifyHumanModal Rendered: open:", props.open);

  const onLoad = () => {
    // console.log("hCaptcha Loaded");
    captchaRef.current.execute();
  };

  useEffect(() => {
    if (token) {
      attemptToVerify(token);
    }
  }, [token]);

  // New useEffect hook based on the hCaptcha team's suggestion
  useEffect(() => {
    let timeoutId;

    const executeCaptcha = async () => {
      if (captchaRef.current?.isReady()) {
        try {
          const captchaResponse = await captchaRef.current.execute({
            async: true,
          });
          const { response: responseToken } = captchaResponse;
          //   console.log("Verified asynchronously: ", responseToken);
          setToken(responseToken);
        } catch (error) {
          console.error(error);
        }
      } else {
        timeoutId = setTimeout(executeCaptcha, 500);
      }
    };

    executeCaptcha();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  //use effect that gets claled each time we have open=true again
  //analytics.logEvent("web - security - hCaptcha - did show");
  useEffect(() => {
    if (props.open) {
      analytics.logEvent("web - security - hCaptcha - did show");
    }
  }, [props.open]);

  const attemptToVerify = async (token) => {
    if (token) {
      //   console.log(`attemptToVerify: ${token}`);
      analytics.logEvent("web - security - hCaptcha - attempt to verify");
      setIsVerifying(true);
      try {
        const attemptToVerify = await verifyHCaptcha(token);
        // reset the captcha after the verification attempt
        captchaRef.current.resetCaptcha();
        if (attemptToVerify.success === true) {
          analytics.logEvent("web - security - hCaptcha - did verify");
          props.onVerified();
          return;
        } else {
          // if the verification fails, we want to show the user an error
          throw new Error("Failed to verify hCaptcha");
        }
      } catch (error) {
        analytics.logEvent("web - security - hCaptcha - did fail to verify", {
          error: error.message,
        });
        console.error(error);
        NotificationManager.error("Failed to verify hCaptcha");
      }
    }
  };

  const handleClose = () => {
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={handleClose} sx={{ background: "red" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Use 'row' if you prefer a horizontal layout
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Adjust or remove this as per your layout needs
        }}
      >
        <form>
          <HCaptcha
            sitekey="143549b2-09ea-4dbc-ac2b-d6b160ae8152"
            onLoad={onLoad}
            onVerify={setToken}
            ref={captchaRef}
            onError={(error) => {
              console.error("hCaptcha Error:", error);
            }}
          />
        </form>
      </Box>
    </Modal>
  );
};
