import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_MAGIC_LINK_SENT,
  LOGIN_EMAIL_CHANGED,
  LOGIN_PASSWORD_CHANGED,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGOUT_USER,
  JWT_LOGIN_REQUEST,
  JWT_LOGIN_SUCCESS,
  JWT_LOGIN_FAILURE,
  FETCHED_AUTHED_USER_DETAILS,
  UPDATED_AUTHED_USER_DETAILS,
  ON_SOCIAL_ACCOUNT_ADDED,
  FETCHING_CURRENT_USER_DETAILS,
  FIREBASE_AUTH_STATUS_CHANGED,
  SHOW_ACCOUNT_MODAL,
  SHOW_AUTH_MODAL,
  HIDE_ACCOUNT_MODAL,
  HIDE_AUTH_MODAL,
  SET_ACTIVE_SUPERSCRIPT,
  RESET_ACTIVE_SCRIPT,
  FETCH_SUPERSCRIPT,
  UPDATED_USER_PROFILE_DETAILS,
  SHOW_AUTH_MODAL_FOR_DELETE_USER,
  FETCHED_USER_DETAILS,
  SHOW_PRICING_MODAL,
  HIDE_PRICING_MODAL,
  SHOW_PURCHASING_ONBOARDING_FLOW,
  HIDE_PURCHASING_ONBOARDING_FLOW,
  SHOW_SCRIPT_SELECTOR_MODAL,
  HIDE_SCRIPT_SELECTOR_MODAL,
} from "actions/Types";
import { analytics } from "analytics/analytics";
import { User } from "kre8tv/model";

/**
 * Initial auth user
 */
const INIT_STATE = {
  //if the user's is logged in or not (via firebase auth)
  isAuthed: false,
  didUpdatedAuthStatus: false,
  user_id: localStorage.getItem("user_id"),
  //FIRUser (firebase user model object)
  user: localStorage.getItem("user") ? localStorage.getItem("user") : null, //firebase auth data
  //VLUser model object
  user_details: localStorage.getItem("user_details")
    ? new User(JSON.parse(localStorage.getItem("user_details")))
    : null,
  loading: false,
  email: "",
  password: "",
  error: "",
  session: {
    token: undefined,
  },
  displayName: "",
  firstName: localStorage.getItem("first_name"),
  lastName: localStorage.getItem("last_name"),
  isAdmin: localStorage.getItem("isAdmin") ?? false,
  invite_key: undefined,
  socialAccounts: undefined,
  completed_onboarding_at: undefined,
  showAuthModal: false,
  showAccountModal: false,
  authForDeleteUser: false,
  showPricingModal: false,
  showPurchasingOnboardingFlowModal: false,
  showScriptSelectorModal: false,
};

const onAuthenticatedUser = (userId, fullName, firstName, lastName, email) => {
  analytics
    .setUserId(userId)
    .setFullName(fullName)
    .setFirstName(firstName)
    .setLastName(lastName)
    .setEmail(email);

  // console.log("onAuthenticatedUser called for user id:", userId);
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCHED_USER_DETAILS:
      return {
        ...state,
        user_details: action.payload,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        displayName: action.payload.display_name,
        email: action.payload.email,
      };
    case FETCHING_CURRENT_USER_DETAILS:
      return { ...state, loading: true };
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };

    case LOGIN_USER_FAILURE:
      return { ...state, loading: false, password: "", error: action.payload };

    case LOGIN_USER_MAGIC_LINK_SENT:
      return { ...state, loading: false };

    case LOGIN_EMAIL_CHANGED:
      return { ...state, email: action.payload };

    case LOGIN_PASSWORD_CHANGED:
      return { ...state, password: action.payload };

    case SIGNUP_USER:
      return { ...state, loading: true };

    case SIGNUP_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };

    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case JWT_LOGIN_REQUEST:
      return { ...state, loading: true, loggingIn: true, user: action.payload };

    case JWT_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggingIn: true,
        user: action.payload,
      };

    case JWT_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggingIn: true,
        user: action.payload,
      };
    case FETCHED_AUTHED_USER_DETAILS:
      const user = action.payload.user;
      const socialAccounts = action.payload.socialAccounts;
      onAuthenticatedUser(
        user.id,
        user.display_name,
        user.first_name,
        user.last_name,
        user.email
      );
      localStorage.setItem("isAdmin", user.is_admin);
      return {
        ...state,
        loading: false,
        user_id: user.id,
        user_details: user,
        displayName: user.display_name,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        isAdmin: user.is_admin,
        invite_key: user.invite_key,
        socialAccounts: socialAccounts,
      };
    case UPDATED_AUTHED_USER_DETAILS:
      onAuthenticatedUser(
        action.payload.id,
        action.payload.display_name,
        action.payload.first_name,
        action.payload.last_name,
        action.payload.email,
        action.payload.invite_key
      );
      localStorage.setItem("isAdmin", action.payload.is_admin);
      return {
        ...state,
        user: action.payload,
        user_id: action.payload.id,
        user_details: action.payload,
        displayName: action.payload.display_name,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        email: action.payload.email,
        isAdmin: action.payload.is_admin,
        invite_key: action.payload.invite_key,
      };
    case LOGOUT_USER:
      window.location.replace("/");
      return { ...INIT_STATE };
    case ON_SOCIAL_ACCOUNT_ADDED:
      const existing = state.socialAccounts;
      return {
        ...state,
        socialAccounts: existing
          ? [action.payload].concat(existing) //so we move it to first
          : [action.payload],
      };
    case FIREBASE_AUTH_STATUS_CHANGED:
      // console.log("FIREBASE_AUTH_STATUS_CHANGED", action.payload);
      return {
        ...state,
        isAuthed: action.payload,
        didUpdatedAuthStatus: true,
      };
    case UPDATED_USER_PROFILE_DETAILS:
      const {
        id,
        first_name,
        last_name,
        display_name,
        profile_id,
        profilePictureURL,
        title,
        tags,
        email,
      } = action.payload.user;
      // update analytics
      onAuthenticatedUser(id, display_name, first_name, last_name, email);
      return {
        ...state,
        user_details: {
          ...state.user_details,
          email: email,
          profile_id: profile_id,
          first_name: first_name,
          last_name: last_name,
          display_name: display_name,
          profilePictureURL: profilePictureURL,
          title: title,
          tags: tags,
        },
        firstName: first_name,
        lastName: last_name,
        displayName: display_name,
      };
    case SHOW_ACCOUNT_MODAL:
      return { ...state, showAccountModal: true };
    case SHOW_AUTH_MODAL:
      return { ...state, showAuthModal: true, authForDeleteUser: false };
    case HIDE_ACCOUNT_MODAL:
      return { ...state, showAccountModal: false };
    case HIDE_AUTH_MODAL:
      return { ...state, showAuthModal: false };
    case SHOW_AUTH_MODAL_FOR_DELETE_USER:
      return { ...state, showAuthModal: true, authForDeleteUser: true };
    case SET_ACTIVE_SUPERSCRIPT:
      return { ...state, showAccountModal: false, showPricingModal: false };
    case RESET_ACTIVE_SCRIPT:
      return { ...state, showAccountModal: false, showPricingModal: false };
    case FETCH_SUPERSCRIPT:
      return { ...state, showAccountModal: false };
    case SHOW_PRICING_MODAL:
      return {
        ...state,
        showPricingModal: true,
        pricingMode: action.payload.pricing_mode,
      };
    case HIDE_PRICING_MODAL:
      return { ...state, showPricingModal: false };
    case SHOW_PURCHASING_ONBOARDING_FLOW:
      return { ...state, showPurchasingOnboardingFlowModal: true };
    case HIDE_PURCHASING_ONBOARDING_FLOW:
      return { ...state, showPurchasingOnboardingFlowModal: false };
    case SHOW_SCRIPT_SELECTOR_MODAL:
      return {
        ...state,
        shouldShowScriptSelectorModal: true,
        scriptSelectorMode: action.payload.mode,
      };
    case HIDE_SCRIPT_SELECTOR_MODAL:
      return { ...state, shouldShowScriptSelectorModal: false };
    default:
      return { ...state };
  }
};
