import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { TextField, Button, Fab, Box, Typography } from "@mui/material";
import SCButton from "../../components/Buttons/SCButton";
import SCTextField from "../../components/Buttons/SCTextField";
import { Link } from "@mui/material";
import { NotificationManager } from "react-notifications";
// redux action
import {
  signinUserWithFirebase,
  sendUserFirebaseEmailLink,
  onEmailChanged,
  onPasswordChanged,
  signinUserWithGoogle,
  signinUserWithApple,
  signinUserWithMicrosoft,
} from "actions";
import { analytics } from "analytics/analytics";

import LogoImage from "../../assets/Images/logo_mixed.svg";

import AppleIconImage from "../../assets/Images/apple.svg";
import GoogleIconImage from "../../assets/Images/google.svg";
import MicrosoftIconImage from "../../assets/Images/microsoft-icon.svg";
import { VerifyHumanModal } from "./VerifyHumanModal";

class SigninFirebase extends Component {
  //constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        blankEmail: false,
        invalidEmail: false,
        blankPassword: false,
      },
      shouldShowPrecheckModal: false,
      onVerifySuccessContinueWithMethod: null,
      value: 0,
    };
    this.verifyHumanModalRef = React.createRef();
  }
  /**
   * Function to login user using Firebase
   */
  async onUserLogin() {
    const { email, password } = this.props;
    let fieldValidationErrors = this.state.formErrors;
    if (email === "") {
      fieldValidationErrors.blankEmail = true;
    }
    /* we're not using password authenication for now, simply authing through one's email */
    // if (password === "") {
    //   fieldValidationErrors.blankPassword = true;
    // }
    if (!this.validateEmail(email)) {
      fieldValidationErrors.invalidEmail = true;
    }
    await this.setState({
      formErrors: fieldValidationErrors,
    });

    let forDeletingUser = this.props.authForDeleteUser === true;
    if (email !== "") {
      //now check if valid email address
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) {
        NotificationManager.error("Please enter a valid email address");
        return;
      }

      var userDetails = { email };
      analytics.logEvent("web - flow - sign in - did submit login email");
      //get full current url
      const redirectToURL = window.location.href;
      this.props.sendUserFirebaseEmailLink(
        userDetails,
        this.props.history,
        redirectToURL,
        forDeletingUser
      );
      this.onUserSignUp();
    }
  }

  /**
   * Function to detect email changes
   */
  onEmailChanged(e) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.blankEmail = false;
    // fieldValidationErrors.invalidEmail = false;
    this.setState({ formErrors: fieldValidationErrors, email: e.target.value });
    this.props.onEmailChanged(e.target.value);
  }

  /**
   * Function to detect login password changes
   */
  onPasswordChanged(e) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.blankPassword = false;
    this.setState({ formErrors: fieldValidationErrors });
    this.props.onPasswordChanged(e.target.value);
  }

  /**
   * Function is use for check the email validation.
   */
  validateEmail(email) {
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return emailValid;
  }

  /**
   * Function to show error
   */
  renderError() {
    if (this.props.error) {
      return (
        <div style={{ backgroundColor: "white" }}>
          <TextField variant="standard">{this.props.error}</TextField>
        </div>
      );
    }
  }

  /**
   * On User Sign Up
   */
  onUserSignUp() {
    // this.props.history.push("/signup");
    this.props.onUserSignUp();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    analytics.logEvent("web - flow - sign in - did show login container");
  }

  render() {
    const { blankEmail, blankPassword, invalidEmail } = this.state.formErrors;
    const { email, error, authForDeleteUser } = this.props;
    const subtitle = authForDeleteUser
      ? "Sign in to delete your account"
      : "Get started with a free account";

    const onVerifiedSuccess = () => {
      // console.log("Verified");
      let method = this.state.onVerifySuccessContinueWithMethod;
      // console.log("onVerifiedSuccess - state:", this.state);
      continueWithAuth(method);
      this.setState({
        shouldShowPrecheckModal: false,
        onVerifySuccessContinueWithMethod: null,
      });
    };

    const onCloseVerifyModal = () => {
      this.setState({
        shouldShowPrecheckModal: false,
        onVerifySuccessContinueWithMethod: null,
      });
    };

    const onPrecheckSigInButtonClicked = (method) => {
      // first check, if we should precheck the sign in button
      let shouldPrecheck = shouldPrecheckUserSignIn();

      if (shouldPrecheck) {
        this.setState({
          shouldShowPrecheckModal: true,
          onVerifySuccessContinueWithMethod: method,
        });
      } else {
        continueWithAuth(method);
      }
    };

    const continueWithAuth = (method) => {
      // console.log(`Continue with auth method: ${method}`);
      const {
        signinUserWithApple,
        signinUserWithGoogle,
        signinUserWithMicrosoft,
        history,
      } = this.props;
      const authForDeleteUser = this.props.authForDeleteUser;

      switch (method) {
        case "apple":
          signinUserWithApple(history, authForDeleteUser);
          break;
        case "google":
          signinUserWithGoogle(history, authForDeleteUser);
          break;
        case "microsoft":
          signinUserWithMicrosoft(history, authForDeleteUser);
          break;
        case "email":
          this.onUserLogin();
          break;
        default:
          console.error("Invalid auth method");
          break;
      }
    };

    //NOTE - this is a temporary fix to block sign in
    const blockSignin = false;
    return (
      <Box backgroundColor="white" p={3} height="100vh">
        <Box
          height={1}
          style={{ background: "#f2f3f8", borderRadius: "30px" }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent="space-around"
          alignItems={"center"}
          position={"relative"}
          visibility={blockSignin ? "hidden" : "visible"}
        >
          <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <Box
              height="100%"
              className="session-box"
              mx="auto"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="100%">
                <Box
                  textAlign="center"
                  className="session-logo"
                  sx={{ marginBottom: "0px !important" }}
                >
                  <img
                    className="img-fluid"
                    alt="img"
                    width="315"
                    src={LogoImage}
                  />
                </Box>

                <Box textAlign="center" sx={{ marginBottom: "100px" }}>
                  <Typography variant="h4" color="textPrimary">
                    {subtitle}
                  </Typography>
                </Box>

                <Box>
                  <form className="login-form text-center">
                    <Box>
                      <SCTextField
                        align="center"
                        required
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottomWidth: "2px", // Increase the border bottom width
                          },
                          "& .MuiInputBase-root": {
                            paddingBottom: "8px", // Increase the vertical padding
                          },
                          "& .MuiInputBase-input": {
                            textAlign: "center",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        id="username"
                        type="email"
                        name="email"
                        placeholder="Please enter your email address"
                        className="outlined-input"
                        value={email}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            onPrecheckSigInButtonClicked("email");
                            e.preventDefault();
                          }
                        }}
                        onChange={(email) => this.onEmailChanged(email)}
                        error={
                          blankEmail || invalidEmail || error ? true : false
                        }
                      />
                      {blankEmail && (
                        <Box
                          component="span"
                          color="error.main"
                          textAlign="left"
                          display="block"
                          fontSize="subtitle2.fontSize"
                          pt={1}
                        >
                          Email cannot be empty.
                        </Box>
                      )}
                      {!blankEmail && invalidEmail && (
                        <Box
                          component="span"
                          color="error.main"
                          textAlign="left"
                          display="block"
                          fontSize="subtitle2.fontSize"
                          pt={1}
                        >
                          The email address is badly formatted.
                        </Box>
                      )}
                    </Box>
                    <Box mb={3}>
                      {blankPassword && (
                        <Box
                          component="span"
                          color="error.main"
                          textAlign="left"
                          display="block"
                          fontSize="subtitle2.fontSize"
                          pt={1}
                        >
                          Password cannot be empty
                        </Box>
                      )}
                    </Box>
                    <Box mb="15px">
                      <SCButton
                        color="primary"
                        className="btn-block blockBtn w-100"
                        variant="contained"
                        size="large"
                        onClick={() => onPrecheckSigInButtonClicked("email")}
                        style={{
                          backgroundColor:
                            this.state.email && this.state.email.length > 0
                              ? "#0C1DB8"
                              : "#C0C3DC",
                        }}
                      >
                        Continue
                      </SCButton>
                    </Box>
                    <MoreMethods
                      onPrecheckSigInButtonClicked={(method) =>
                        onPrecheckSigInButtonClicked(method)
                      }
                    />
                    <VerifyHumanModal
                      verifyHumanModalRef={this.verifyHumanModalRef}
                      open={this.state.shouldShowPrecheckModal}
                      key={Date.now()}
                      onVerified={onVerifiedSuccess}
                      onClose={onCloseVerifyModal}
                    />
                  </form>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box position="absolute" bottom={45} width={1} textAlign="center">
            <TermsAndPrivacy />
          </Box>
        </Box>
        <Box
          visibility={!blockSignin ? "hidden" : "visible"}
          height={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent="center"
          width={1}
          alignItems={"center"}
          position={"absolute"}
          top={0}
          left={0}
          textAlign={"center"}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            fontSize="24px"
            fontWeight="bold"
          >
            <Link
              onClick={() => {
                window.location.href = "https://supercreator.ai";
              }}
              fontSize="24px"
              fontWeight="bold"
              style={{
                cursor: "pointer",
              }}
            >
              Supercreator.ai{" "}
            </Link>
            <Box ml={0.5}>{`is coming soon!`}</Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const TermsAndPrivacy = () => {
  return (
    <Box textAlign="center" fontSize="12px">
      By using Supercreator.ai you agree to our <br />
      <a href="/terms-of-service" className="linkPrimary">
        {" "}
        Terms of Service{" "}
      </a>
      and
      <a href="/privacy-policy" className="linkPrimary">
        {" "}
        Privacy Policy
      </a>
    </Box>
  );
};

const MoreMethods = (props) => {
  return (
    <>
      <Box mb="15px" mt="15px">
        Or
      </Box>
      <Box>
        <SCButton
          color="primary"
          className="btn-block blockBtn w-100"
          variant="contained"
          size="large"
          onClick={() => props.onPrecheckSigInButtonClicked("apple")}
          style={{ backgroundColor: "#212121" }}
        >
          <img
            src={AppleIconImage}
            alt="apple"
            width="20"
            style={{ position: "absolute", left: "20px" }}
          />
          Sign In with Apple
        </SCButton>
      </Box>
      <Box mt="15px">
        <SCButton
          style={{ backgroundColor: "#C75340" }}
          color="primary"
          className="btn-block blockBtn w-100"
          variant="contained"
          size="large"
          onClick={() => props.onPrecheckSigInButtonClicked("google")}
        >
          <img
            src={GoogleIconImage}
            alt="google"
            width="20"
            style={{ position: "absolute", left: "20px" }}
          ></img>
          Sign In With Google
        </SCButton>
      </Box>
      <Box mt="15px">
        <SCButton
          style={{ backgroundColor: "#518EF8" }}
          color="primary"
          className="btn-block blockBtn w-100"
          variant="contained"
          size="large"
          onClick={() => props.onPrecheckSigInButtonClicked("microsoft")}
        >
          <img
            src={MicrosoftIconImage}
            alt="google"
            width="20"
            style={{ position: "absolute", left: "20px" }}
          ></img>
          Sign In With Microsoft
        </SCButton>
      </Box>
    </>
  );
};

// if user is in brazil, china ,or turkey, indea, we should precheck the user sign in
const shouldPrecheckUserSignIn = () => {
  const country = navigator.language.includes("-")
    ? navigator.language.split("-")[1]
    : "US";
  // console.log(`Country: ${country}`);
  // return country === "BR" || country === "CN" || country === "TR";
  //check just lowercase
  return (
    country.toLowerCase() === "br" ||
    country.toLowerCase() === "cn" ||
    country.toLowerCase() === "tr" ||
    country.toLowerCase() === "in"
  );
  // return true;
};

const mapStateToProps = ({ authUser, settings }) => {
  const { loading, email, password, error } = authUser;
  return { loading, email, password, error };
};

export default connect(mapStateToProps, {
  signinUserWithFirebase,
  sendUserFirebaseEmailLink,
  onEmailChanged,
  onPasswordChanged,
  signinUserWithApple,
  signinUserWithGoogle,
  signinUserWithMicrosoft,
})(SigninFirebase);
