/**
 * App Redux Action Types
 */

// Auth Actions
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGING_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_USER_MAGIC_LINK_SENT = "LOGIN_USER_MAGIC_LINK_SENT";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_EMAIL_CHANGED = "SIGNIN_EMAIL_CHANGED";
export const LOGIN_PASSWORD_CHANGED = "SIGNIN_PASSWORD_CHANGED";
export const FETCHING_CURRENT_USER_DETAILS = "FETCHING_CURRENT_USER_DETAILS";
export const FETCHED_AUTHED_USER_DETAILS = "FETCHED_AUTHED_USER_DETAILS";
export const UPDATED_AUTHED_USER_DETAILS = "UPDATED_AUTHED_USER_DETAILS";
export const UPDATED_USER_PROFILE_DETAILS = "UPDATED_USER_PROFILE_DETAILS";
export const FETCHED_USER_DETAILS = "FETCHED_USER_DETAILS";
export const FIREBASE_AUTH_STATUS_CHANGED = "FIREBASE_AUTH_STATUS_CHANGED";
export const SHOW_AUTH_MODAL = "SHOW_AUTH_MODAL";
export const SHOW_AUTH_MODAL_FOR_DELETE_USER =
  "SHOW_AUTH_MODAL_FOR_DELETE_USER";
export const HIDE_AUTH_MODAL = "HIDE_AUTH_MODAL";
export const SHOW_ACCOUNT_MODAL = "SHOW_ACCOUNT_MODAL";
export const HIDE_ACCOUNT_MODAL = "HIDE_ACCOUNT_MODAL";
export const SHOW_PRICING_MODAL = "SHOW_PRICING_MODAL";
export const HIDE_PRICING_MODAL = "HIDE_PRICING_MODAL";
//Auth Actions - Social Accounts
export const ON_SOCIAL_ACCOUNT_ADDED = "ON_SOCIAL_ACCOUNT_ADDED";
export const SHOW_PURCHASING_ONBOARDING_FLOW =
  "SHOW_PURCHASING_ONBOARDING_FLOW";
export const HIDE_PURCHASING_ONBOARDING_FLOW =
  "HIDE_PURCHASING_ONBOARDING_FLOW";
export const HIDE_INVITATION_CODE_BLOCKER = "HIDE_INVITATION_CODE_BLOCKER";
export const SHOW_SCRIPT_SELECTOR_MODAL = "SHOW_SCRIPT_SELECTOR_MODAL";
export const HIDE_SCRIPT_SELECTOR_MODAL = "HIDE_SCRIPT_SELECTOR_MODAL";

// Homepage
export const GET_HOME_SCREEN_FEED = "GET_HOME_SCREEN_FEED"; //fetching
export const HOME_SCREEN_FEED_FETCH_SUCCESS = "HOME_SCREEN_FEED_FETCH_SUCCESS"; //success
export const HOME_SCREEN_FEED_FETCH_FAILURE = "HOME_SCREEN_FEED_FETCH_FAILURE"; //failed

// Abilities
export const GET_ABILITIES = "GET_HOME_SCREEN_FEED";
export const SAVE_RECIPE_SUCCESS = "SAVE_RECIPE_SUCCESS";
export const SAVE_RECIPE_FAILURE = "SAVE_RECIPE_FAILURE";

// Missions
export const GET_MISSIONS = "GET_MISSIONS";

// Superscripts
export const DELETE_SUPERSCRIPT = "DELETE_SUPERSCRIPT";
export const ADD_SUPERSCRIPT = "ADD_SUPERSCRIPT";
export const GET_SUPERSCRIPTS = "GET_SUPERSCRIPTS";
export const GET_FEATURED_SCRIPTS = "GET_FEATURED_SCRIPTS";
export const SET_ACTIVE_SUPERSCRIPT = "SET_ACTIVE_SUPERSCRIPT";
export const RESET_ACTIVE_SCRIPT = "RESET_ACTIVE_SCRIPT";
export const SHOW_NEED_PERMISSION_FOR_SCRIPT =
  "SHOW_NEED_PERMISSION_FOR_SCRIPT";
export const ERROR_FETCH_SUPERSCRIPT = "ERROR_FETCH_SUPERSCRIPT";
export const UPDATE_SUPERSCRIPT_LOCALLY = "UPDATE_SUPERSCRIPT_LOCALLY";
export const UPDATE_SUPERSCRIPT = "UPDATE_SUPERSCRIPT";
export const IS_SAVING_SCRIPT = "IS_SAVING_SCRIPT";
export const REPLACE_SUPERSCRIPT = "REPLACE_SUPERSCRIPT";
export const APPEND_TEXT_TO_SCRIPT_RESULT = "APPEND_TEXT_TO_SCRIPT_RESULT";
export const UNDO_TEXT_TO_SCRIPT_RESULT = "UNDO_TEXT_TO_SCRIPT_RESULT";
export const SHOW_SHARE_MODAL = "SHOW_SHARE_MODAL";
export const ADD_SCENE = "ADD_SCENE";
export const UPDATE_SCENE = "UPDATE_SCENE";
export const DELETE_SCENE = "DELETE_SCENE";
export const FETCH_SUPERSCRIPT = "FETCH_SUPERSCRIPT";
export const UPDATE_SCRIPT_EDITOR_SETTINGS = "UPDATE_SCRIPT_EDITOR_SETTINGS";
export const SHOULD_FETCH_SUPERSCRIPT = "SHOULD_FETCH_SUPERSCRIPT";
export const UPDATE_SCENES = "UPDATE_SCENES";
export const FETCH_MY_SUPERSCRIPTS = "FETCH_MY_SUPERSCRIPTS";
export const ERROR_FETCHING_ASSETS = "ERROR_FETCHING_ASSETS";
export const SET_ACTIVE_SCENE = "SET_ACTIVE_SCENE";
export const GENERATE_NEXT_SCENE_FOR_SCRIPT = "GENERATE_NEXT_SCENE_FOR_SCRIPT";
export const REPLACE_SCENE = "REPLACE_SCENE";
export const FETCH_SCENES = "FETCH_SCENES";
export const PUSH_SCRIPT_TO_TOP_LATEST_SCRIPTS =
  "PUSH_SCRIPT_TO_TOP_LATEST_SCRIPTS";
export const RESET_ACTIVE_SCRIPT_RECIPE = "RESET_ACTIVE_SCRIPT_RECIPE";
export const SET_ACTIVE_SCRIPT_RECIPE = "SET_ACTIVE_SCRIPT_RECIPE";
//..Folders
export const FETCHING_USER_HOME_FOLDERS = "FETCHING_USER_HOME_FOLDERS";
export const FETCHED_USER_HOME_FOLDERS = "FETCHED_USER_HOME_FOLDERS";
export const MOVE_SCRIPT_TO_FOLDER = "MOVE_SCRIPT_TO_FOLDER";
export const SAVE_EXPANSION_STATE_FOR_FOLDER =
  "SAVE_EXPANSION_STATE_FOR_FOLDER";
export const ON_FOLDER_CREATED = "ON_FOLDER_CREATED";
export const ON_FOLDER_UPDATED = "ON_FOLDER_UPDATED";
export const ON_FOLDER_DELETED = "ON_FOLDER_DELETED";
export const ON_SCRIPT_PERMISSIONS_SETTINGS_UPDATED =
  "ON_SCRIPT_PERMISSIONS_SETTINGS_UPDATED";

//Creations
export const GET_CREATIONS = "GET_CREATIONS";
export const GET_FAVORITED_CREATIONS = "GET_FAVORITED_CREATIONS";
export const COPY_CREATION_TO_CLIPBOARD = "COPY_CREATION_TO_CLIPBOARD";
export const FAVORITE_CREATION = "FAVORITE_CREATION";
export const UNFAVORITE_CREATION = "UNFAVORITE_CREATION";
export const IS_CREATION_FAVORITE = "IS_CREATION_FAVORITE";

//Editor
export const POWER_UP_CONTENT = "POWER_UP_CONTENT";
export const PICK_SUPERPOWER_FOR_SELECTED_CONTENT =
  "PICK_SUPERPOWER_FOR_SELECTED_CONTENT";
export const PICK_SUPERHERO_FOR_SELECTED_CONTENT =
  "PICK_SUPERHERO_FOR_SELECTED_CONTENT";

export const DISMISS_POWER_UP_CONTENT = "DISMISS_POWER_UP_CONTENT";

//Supercreators
export const GET_SUPERCREATORS = "GET_SUPERCREATORS";

//Superheroes
export const GET_SUPERHEROES = "GET_SUPERHEROES";

//Content Assets
export const ADD_ASSET_SEARCH_TO_HISTORY = "ADD_ASSET_SEARCH_TO_HISTORY";
export const UPDATE_ASSET_SEARCH_HISTORY_RESULT_EXPANSION =
  "UPDATE_ASSET_SEARCH_HISTORY_RESULT_EXPANSION";
export const UPDATE_REMOTE_TEXT_TO_IMAGE_MODELS =
  "UPDATE_REMOTE_TEXT_TO_IMAGE_MODELS";
export const ADD_AI_RESULT_TO_HISTORY = "ADD_AI_RESULT_TO_HISTORY";
export const ON_AI_ASSETS_RESULT_FINISHED = "ON_AI_ASSETS_RESULT_FINISHED";
export const ASSET_UPLOAD_DID_START = "ASSET_UPLOAD_DID_START";
export const ASSET_UPLOAD_DID_FINISH = "ASSET_UPLOAD_DID_FINISH";
export const ASSET_UPLOAD_DID_FAIL = "ASSET_UPLOAD_DID_FAIL";
export const ASSET_UPLOAD_DID_UPDATE_PROGRESS =
  "ASSET_UPLOAD_DID_UPDATE_PROGRESS";
export const ASSET_UPLOAD_REMOVE_FROM_QUEUE = "ASSET_UPLOAD_REMOVE_FROM_QUEUE";
export const ADD_ASSET_TO_USER_UPLOADED_ASSETS =
  "ADD_ASSET_TO_USER_UPLOADED_ASSETS";
export const USER_UPLOADED_ASSETS_FETCHED = "USER_UPLOADED_ASSETS_FETCHED";

//Creators
export const GET_CREATORS = "GET_CREATORS";
export const FAILED_GET_CREATORS = "FAILED_GET_CREATORS";
export const GET_CREATOR_AVATARS = "GET_CREATOR_AVATARS";
export const FAILED_GET_CREATOR_AVATARS = "FAILED_GET_CREATOR_AVATARS";

//News
export const GET_NEWS_ITEMS = "GET_NEWS_ITEMS";

// Chat App Actions
export const CHAT_CONVERSATIONS_TYPE = "CHAT_CONVERSATIONS_TYPE";
export const CHAT_WITH_SELECTED_USER = "CHAT_WITH_SELECTED_USER";
export const SEND_MESSAGE_TO_USER = "SEND_MESSAGE_TO_USER";
export const UPDATE_USERS_SEARCH = "UPDATE_USERS_SEARCH";
export const SEARCH_USERS = "SEARCH_USERS";
export const GET_RECENT_CHAT_USERS = "GET_RECENT_CHAT_USERS";
export const GET_DEFAULT_SELECTED_USER = "GET_DEFAULT_SELECTED_USER";

// App Settings
export const COLLAPSED_SIDEBAR = "COLLAPSED_SIDEBAR";
export const MINI_SIDEBAR = "MINI_SIDEBAR";
export const DARK_MODE = "DARK_MODE";
export const RTL = "RTL";
export const HORIZONTAL_MENU = "HORIZONTAL_MENU";
export const CHOOSE_THEME = "CHOOSE_THEME";
export const NOTIFICATION_SIDEBAR = "NOTIFICATION_SIDEBAR";

// Ecommerce
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const DELETE_ITEM_FROM_CART = "DELETE_ITEM_FROM_CART";
export const ON_QUANTITY_CHANGE = "ON_QUANTITY_CHANGE";
export const ADD_ITEM_TO_WISHLIST = "ADD_ITEM_TO_WISHLIST";
export const DELETE_ITEM_FROM_WISHLIST = "DELETE_ITEM_FROM_WISHLIST";

// Contact Grid
export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

// Admin  related
export const UPDATE_MOST_RECENTLY_CONNECTED_USERS =
  "UPDATE_MOST_RECENTLY_CONNECTED_USERS";
export const UPDATE_MOST_RECENTLY_CREATED_SCRIPTS =
  "UPDATE_MOST_RECENTLY_CREATED_SCRIPTS";
export const UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS =
  "UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS";

// Mail App
export const GET_EMAILS = "GET_EMAILS";
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS";
export const GET_EMAIL_FAILURE = "GET_EMAIL_FAILURE";
export const SET_EMAIL_AS_STAR = "SET_EMAIL_AS_STAR";
export const READ_EMAIL = "READ_EMAIL";
export const HIDE_LOADING_INDICATOR = "HIDE_LOADING_INDICATOR";
export const FETCH_EMAILS = "FETCH_EMAILS";
export const ON_SELECT_EMAIL = "ON_SELECT_EMAIL";
export const UPDATE_EMAIL_SEARCH = "UPDATE_EMAIL_SEARCH";
export const SEARCH_EMAIL = "SEARCH_EMAIL";
export const ON_DELETE_MAIL = "ON_DELETE_MAIL";
export const ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING =
  "ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING";
export const GET_SENT_EMAILS = "GET_SENT_EMAILS";
export const GET_INBOX = "GET_INBOX";
export const GET_DRAFTS_EMAILS = "GET_DRAFTS_EMAILS";
export const GET_SPAM_EMAILS = "GET_SPAM_EMAILS";
export const GET_TRASH_EMAILS = "GET_TRASH_EMAILS";
export const ON_EMAIL_MOVE_TO_FOLDER = "ON_EMAIL_MOVE_TO_FOLDER";
export const SELECT_ALL_EMAILS = "SELECT_ALL_EMAILS";
export const UNSELECT_ALL_EMAILS = "UNSELECT_ALL_EMAILS";
export const ON_SEND_EMAIL = "ON_SEND_EMAIL";
export const EMAIL_SENT_SUCCESSFULLY = "EMAIL_SENT_SUCCESSFULLY";
export const FILTER_EMAILS_WITH_LABELS = "FILTER_EMAILS_WITH_LABELS";
export const ON_SPAM_MAIL = "ON_SPAM_MAIL";
export const ON_DRAFT_MAIL = "ON_DRAFT_MAIL";
export const ON_DELETE_EMAIL_FROM_TRASH_FOLDER =
  "ON_DELETE_EMAIL_FROM_TRASH_FOLDER";

// User Settings
export const EMAIL_ON_SWITCH_CHANGE = "EMAIL_ON_SWITCH_CHANGE";
export const COPY_INVITE_LINK = "COPY_INVITE_LINK";
export const SET_LANGUAGE = "SET_LANGUAGE";

// Menu List
export const TOGGLE_MENU = "TOGGLE_MENU";
export const TOGGLE_THIRD_MENU = "TOGGLE_THIRD_MENU";
export const TOGGLE_FOURTH_MENU = "TOGGLE_FOURTH_MENU";
export const ONLOAD_TOGGLE_MENU = "ONLOAD_TOGGLE_MENU";

//JWT
export const JWT_LOGIN_REQUEST = "JWT_USERS_LOGIN_REQUEST";
export const JWT_LOGIN_SUCCESS = "JWT_USERS_LOGIN_SUCCESS";
export const JWT_LOGIN_FAILURE = "JWT_USERS_LOGIN_FAILURE";

//Generations
export const FETCHED_GENERATIONS = "FETCHED_GENERATIONS";
export const FETCH_MY_GENERATIONS = "FETCH_MY_GENERATIONS";
export const UPDATE_GENERATION = "UPDATE_GENERATION";

//Account Details
export const FETCHED_ACCOUNT_DETAILS = "FETCHED_ACCOUNT_DETAILS";
export const FETCHED_USER_PERMISSIONS = "FETCHED_USER_PERMISSIONS";
export const RELOAD_ACCOUNT_DETAILS_PAYWALL_STATE =
  "RELOAD_ACCOUNT_DETAILS_PAYWALL_STATE";

//Inspiration sources
export const GET_INSPIRATION_SOURCES = "GET_INSPIRATION_SOURCES";
export const FETCHED_INSPIRATIONS_SOURCES = "FETCHED_INSPIRATIONS_SOURCES";
export const ADDED_INSPIRATION_SOURCE = "ADDED_INSPIRATION_SOURCE";
export const REMOVED_INSPIRATION_SOURCE = "REMOVED_INSPIRATION_SOURCE";

//Inspiration source items
export const GET_INSPIRATION_SOURCE_ITEMS = "GET_INSPIRATION_SOURCE_ITEMS";
export const FETCHED_INSPIRATIONS_SOURCE_ITEMS =
  "FETCHED_INSPIRATIONS_SOURCE_ITEMS";

//Recommendations
export const GET_RECOMMENDATIONS = "GET_RECOMMENDATIONS";
export const FETCHED_RECOMMENDATIONS = "FETCHED_RECOMMENDATIONS";

//Organizations
export const IS_FETCHING_ORGANIZATION = "IS_FETCHING_ORGANIZATION";
export const DID_FETCH_ORGANIZATION = "DID_FETCH_ORGANIZATION";
export const FETCHED_USER_ORGANIZATIONS = "FETCHED_USER_ORGANIZATIONS";
export const DID_UPDATE_ORGANIZATION = "DID_UPDATE_ORGANIZATION";
export const UPDATE_MOST_RECENTLY_UPDATED_ORGANIZATIONS =
  "UPDATE_MOST_RECENTLY_UPDATED_ORGANIZATIONS";
