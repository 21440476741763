import { AssetManager } from "kre8tv/managers/VLAssetManager";
import uploadManager from "kre8tv/managers/VLUploadManager";
import { ContentAsset } from "kre8tv/model";
import { saveAsset } from "services/content-assets.service";

export const uploadAndCreateContentAsset = (
  file_type,
  file,
  file_size,
  thumbnail_file,
  callback
) => {
  switch (file_type) {
    case "image":
      uploadAndCreateContentAssetImage(file, file_size, callback);
      break;
    case "video":
      uploadAndCreateContentAssetVideo(
        file,
        file_size,
        thumbnail_file,
        callback
      );
      break;
    default:
      console.error("Invalid file type");
      return;
  }
};

/**
 * Creates a content asset image from a URL
 * @param {*} url The URL of the image
 * @param {*} callback A callback function to call when the process is complete
 */

export const createContentAssetImageFromURL = async (url) => {
  try {
    const id = new AssetManager().newDocumentID();
    let asset_json = {
      id: id,
      type: "image",
      url: url,
      size: 0,
      source_url: url,
      user_uploaded: false,
    };

    let asset = ContentAsset.fromJSON(asset_json);

    // now attempt to save the asset to the on the backend
    let saveAssetResponse = await saveAsset(asset);
    if (saveAssetResponse && saveAssetResponse.asset) {
      return saveAssetResponse.asset; // Return the asset to the caller
    } else {
      throw new Error("Error saving asset");
    }
  } catch (error) {
    throw error; // Propagate the error to the caller
  }
};

/**
 * Uploads an image file and creates a ContentAsset
 * @param {*} file The image file
 * @param {*} file_size The size of the image file
 * @param {*} callback A callback function to call when the upload is complete
 */
const uploadAndCreateContentAssetImage = (file, file_size, callback) => {
  uploadManager.uploadFile(
    "assets",
    file,
    async (downloadURL, progress, error) => {
      console.log("👨‍💻 callbacks...", downloadURL, progress, error);
      console.log("this...", this);
      if (error) {
        callback(null, error);
      } else if (downloadURL) {
        const id = new AssetManager().newDocumentID();
        let asset_json = {
          id: id,
          type: "image",
          url: downloadURL,
          size: file_size,
          source_url: downloadURL,
          user_uploaded: true,
        };

        let asset = ContentAsset.fromJSON(asset_json);
        //now attempt to save the asset to the on the backend
        try {
          let saveAssetResponse = await saveAsset(asset);
          if (saveAssetResponse && saveAssetResponse.asset) {
            callback(saveAssetResponse.asset, progress, null);
          } else {
            throw new Error("Error saving asset");
          }
        } catch (error) {
          callback(null, null, error);
        }
      } else if (progress) {
        callback(null, progress);
      }
    }
  );
};

/**
 * Uploads a video file and creates a ContentAsset
 * @param {*} file The video file
 * @param {*} file_size The size of the video file
 * @param {*} thumbnail_file The thumbnail file
 * @param {*} callback A callback function to call when the upload is complete
 * @returns
 **/

const uploadAndCreateContentAssetVideo = (
  file,
  file_size,
  thumbnail_file,
  callback
) => {
  //1. upload thumbnail
  uploadManager.uploadFile(
    "assets",
    thumbnail_file,
    async (thumbnailDownloadURL, thumbnailProgress, error) => {
      console.log(
        "👨‍💻 callbacks...",
        thumbnailDownloadURL,
        thumbnailProgress,
        error
      );
      console.log("this...", this);
      if (error) {
        callback(null, error);
      } else if (thumbnailDownloadURL) {
        //2. upload video
        uploadManager.uploadFile(
          "assets",
          file,
          async (downloadURL, progress, error) => {
            console.log("👨‍💻 callbacks...", downloadURL, progress, error);
            console.log("this...", this);
            if (error) {
              callback(null, error);
            } else if (downloadURL) {
              const id = new AssetManager().newDocumentID();
              let asset_json = {
                id: id,
                type: "video",
                url: thumbnailDownloadURL,
                size: file_size,
                source_url: downloadURL,
                user_uploaded: true,
              };
              let asset = ContentAsset.fromJSON(asset_json);
              //now attempt to save the asset to the on the backend
              try {
                let saveAssetResponse = await saveAsset(asset);
                if (saveAssetResponse && saveAssetResponse.asset) {
                  callback(saveAssetResponse.asset, progress, null);
                } else {
                  throw new Error("Error saving asset");
                }
              } catch (error) {
                callback(null, null, error);
              }
            } else if (progress) {
              callback(null, progress);
            }
          }
        );
      } else if (thumbnailProgress) {
        //only update progress for the main video upload
        callback(null, 1);
      }
    }
  );
};
