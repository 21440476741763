/**
 * Chat App Actions
 */
import {
  ADD_ASSET_SEARCH_TO_HISTORY,
  UPDATE_ASSET_SEARCH_HISTORY_RESULT_EXPANSION,
  UPDATE_REMOTE_TEXT_TO_IMAGE_MODELS,
  ADD_AI_RESULT_TO_HISTORY,
  ON_AI_ASSETS_RESULT_FINISHED,
  ASSET_UPLOAD_DID_START,
  ASSET_UPLOAD_DID_FINISH,
  ASSET_UPLOAD_DID_FAIL,
  ASSET_UPLOAD_DID_UPDATE_PROGRESS,
  ASSET_UPLOAD_REMOVE_FROM_QUEUE,
  USER_UPLOADED_ASSETS_FETCHED,
  ADD_ASSET_TO_USER_UPLOADED_ASSETS,
} from "actions/Types";
import {
  createContentAssetImageFromURL,
  uploadAndCreateContentAsset,
} from "helpers/content-asset-upload-helper";
import uploadManager from "kre8tv/managers/VLUploadManager";

import { NotificationManager } from "react-notifications";
import {
  deleteContentAsset,
  fetchRemoteTextToImageModels,
  getUserAssets,
} from "services";
import { updateUploadedAssetForSceneId } from "actions";

var _ = require("lodash/core");

export function addSearchResultToHistory(query, assets) {
  return (dispatch) => {
    try {
      const id = _.uniqueId("search_");
      dispatch({
        type: ADD_ASSET_SEARCH_TO_HISTORY,
        payload: { id, title: query, assets, expanded: true },
      });
      return true;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

export function onAIResultsFinished() {
  return (dispatch) => {
    dispatch({
      type: ON_AI_ASSETS_RESULT_FINISHED,
      payload: {},
    });
  };
}

export function addAIAssetResultToHistory(query, assets, showLoading) {
  return (dispatch) => {
    try {
      const id = _.uniqueId("ai_");
      dispatch({
        type: ADD_AI_RESULT_TO_HISTORY,
        payload: {
          id,
          title: query,
          assets,
          expanded: true,
          showLoading: showLoading ?? false,
        },
      });
      return true;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

export function updateSearchResultExpansion(id, expanded) {
  // console.log("updateSearchResultExpansion", id, expanded);
  return (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ASSET_SEARCH_HISTORY_RESULT_EXPANSION,
        payload: { id: id, expanded },
      });
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

export function getRemoteTextToImageModels() {
  return async (dispatch) => {
    try {
      // console.log("Fetching remote text to image models");
      const fetchModels = await fetchRemoteTextToImageModels();
      if (fetchModels && fetchModels.models && fetchModels.models.length > 0) {
        // console.log("Successfully fetched remote text to image models");
        dispatch({
          type: UPDATE_REMOTE_TEXT_TO_IMAGE_MODELS,
          payload: fetchModels.models,
        });
      } else {
        console.error("Error fetching remote text to image models - empty");
      }
      return true;
    } catch (error) {
      console.error("Error fetching remote text to image models", error);
      return null;
    }
  };
}

export const saveImageURLAssetToScene = (url, scene_id) => async (dispatch) => {
  try {
    const resultAsset = await createContentAssetImageFromURL(url);
    if (resultAsset) {
      dispatch(updateUploadedAssetForSceneId(scene_id, resultAsset));
      return resultAsset; // Returning the result to the caller
    } else {
      throw new Error("Error saving image url asset to scene");
    }
  } catch (error) {
    console.error("Error saving image url asset to scene", error);
    throw error; // Propagating the error to the caller
  }
};

export function onBeginUploadAsset(
  file,
  name,
  file_type, // image, video, audio, text
  size_in_bytes,
  thumbnail_url,
  thumbnail_file,
  scene_id
) {
  return (dispatch) => {
    let obj = {
      file,
      name,
      file_type,
      size_in_bytes,
      scene_id,
      thumbnail_url,
    };
    //create a unique id we can use to track this asset
    obj["id"] = _.uniqueId("asset_upload_");
    try {
      dispatch({
        type: ASSET_UPLOAD_DID_START,
        payload: obj,
      });
      uploadAndCreateContentAsset(
        file_type,
        file,
        size_in_bytes,
        thumbnail_file,
        (resultAsset, progress, error) => {
          // console.log("👨‍💻 callbacks...", resultAsset, progress, error);
          // console.log("this...", this);
          if (error) {
            obj["status"] = "failed";
            dispatch({
              type: ASSET_UPLOAD_DID_FAIL,
              payload: obj,
            });
          } else if (resultAsset) {
            resultAsset.scene_id = scene_id;
            obj["status"] = "success";
            dispatch({
              type: ADD_ASSET_TO_USER_UPLOADED_ASSETS,
              payload: resultAsset,
            });
            dispatch({
              type: ASSET_UPLOAD_DID_FINISH,
              payload: obj,
            });

            if (scene_id) {
              dispatch(updateUploadedAssetForSceneId(scene_id, resultAsset));
            }
            //in 2s - remove the asset from the list (ASSET_UPLOAD_REMOVE_FROM_QUEUE)
            setTimeout(() => {
              dispatch({
                type: ASSET_UPLOAD_REMOVE_FROM_QUEUE,
                payload: obj,
              });
            }, 2000);
          } else if (progress) {
            obj["status"] = "uploading";
            obj["progress"] = progress;
            dispatch({
              type: ASSET_UPLOAD_DID_UPDATE_PROGRESS,
              payload: obj,
            });
          }
        }
      );
    } catch (error) {
      console.error("Error uploading asset", error);
      obj["status"] = "failed";
      dispatch({
        type: ASSET_UPLOAD_DID_FAIL,
        payload: obj,
      });
      return null;
    }
  };
}

export const cancelUploadAsset = (id, file) => {
  return (dispatch) => {
    try {
      uploadManager.cancelUpload(file);
      dispatch({
        type: ASSET_UPLOAD_REMOVE_FROM_QUEUE,
        payload: { id },
      });
    } catch (error) {
      console.error("Error canceling upload", error);
      return null;
    }
  };
};

export const fetchUserUploadedAssets = () => {
  return async (dispatch) => {
    try {
      const results = await getUserAssets();
      const assets = results?.assets;
      if (assets) {
        dispatch({
          type: USER_UPLOADED_ASSETS_FETCHED,
          payload: assets,
        });
      }
      // console.log("👨‍💻 fetchUserUploadedAssets", assets);
      return assets;
    } catch (error) {
      console.error("Error fetching user uploaded assets", error);
      return null;
    }
  };
};

export const deleteAsset = async (id) => {
  try {
    const results = await deleteContentAsset(id);
    // console.log("👨‍💻 content asset deleted", results);
    return results.asset;
  } catch (error) {
    console.error("Error deleting asset", error);
    throw error;
  }
};
