import { IEntity } from "./interfaces/entity.interface";
import { Organization } from "./organization.model";

/**
 * Helper class to manage + track user's ideas training info
 */

class UserBrandAssets {
  stickers: BrandAsset[] = [];
  fonts: BrandAsset[] = [];

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    this.stickers = obj.stickers;
    this.fonts = obj.fonts ?? false;
  }
}

class ExternalAPIKeyObject {
  created_on: number = 0;
  last_updated: number = 0;
  api_key: string = "";
  user_id: string = "";
}

export class ConnectedDeviceInfo {
  name = "";
  os = "";
  version = "";
  last_updated = 0;
  user_id = "";

  constructor(obj?: any) {
    //make sure has all or retunr null
    if (!obj || !obj.name || !obj.os || !obj.version || !obj.last_updated) {
      throw new Error("Missing required properties for ConnectedDeviceInfo");
    }
    this.name = obj.name;
    this.os = obj.os;
    this.version = obj.version;
    this.last_updated = obj.last_updated;
    this.user_id = obj.user_id;
  }

  static fromJSON(obj: any) {
    try {
      return new ConnectedDeviceInfo(obj);
    } catch (e) {
      return null;
    }
  }
}

/**
 * A class to represent a the user's account details
 * i.e private info that is only accessible to the user
 */
export class AccountDetails implements IEntity {
  id: string = "";
  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  completed_onboarding_at?: number;
  membership_tier: string = "none"; //if empty string, means we haven't fetched it yet
  target_audience?: string;
  last_connected_mobile_device?: ConnectedDeviceInfo | null;
  organization_details?: Organization | null;
  //personal membership details
  purchased_membership_tier: string = "none";
  forced_membership_tier: string = "none";
  // team membership details
  purchased_membership_tier_stripe: string = "none";
  // api key (rn support only one key)
  external_api_key_data?: ExternalAPIKeyObject | null;
  // webhooks
  webhooks_urls?: string[];
  //stripe subscription plans
  stripe_subscription_plans?: {
    team_plan: {
      is_active: boolean;
      billing_interval: string;
      seats: number;
      next_charge_date: number;
      cancelled: boolean;
      cancells_on?: number;
    };
    personal_plan: {
      is_active: boolean;
      billing_interval: string;
      seats: number;
      next_charge_date: number;
      cancelled: boolean;
      cancells_on?: number;
    };
  };

  has_beta_access: boolean;
  // add new light oobj to manage their unique referral code
  refer_other_users_info?: {
    code: string;
    created_on: number;
  };

  /// Constructor
  constructor(obj?: any) {
    this.deserialize(
      obj.id,
      obj.created_on,
      obj.last_updated,
      obj.last_updated_by_user_id,
      obj.completed_onboarding_at
    );

    this.membership_tier = obj["membership_tier"] ?? "none";
    this.purchased_membership_tier = obj["purchased_membership_tier"] ?? "none";
    this.forced_membership_tier = obj["forced_membership_tier"] ?? "none";
    this.purchased_membership_tier_stripe =
      obj["purchased_membership_tier_stripe"] ?? "none";
    this.target_audience = obj["target_audience"];
    //mobile connected device info
    this.last_connected_mobile_device = ConnectedDeviceInfo.fromJSON(
      obj.last_connected_mobile_device
    );

    //organization details
    this.organization_details = Organization.fromJSON(
      obj["organization_details"]
    );

    // external api key
    this.external_api_key_data = obj["external_api_key_data"];

    // webhooks
    this.webhooks_urls = obj["webhooks_urls"];

    // stripe subscription plans
    this.stripe_subscription_plans = obj["stripe_subscription_plans"];

    // beta access
    this.has_beta_access = obj["has_beta_access"];

    // refer other users info
    this.refer_other_users_info = obj["refer_other_users_info"];
  }

  /**
   * Custom intializing method with properties
   * @param id
   * @param created_on
   * @param last_updated
   * @param last_updated_by_user_id
   */
  deserialize(
    id: string,
    created_on: number,
    last_updated: number,
    last_updated_by_user_id: string,
    completed_onboarding_at?: number
  ) {
    this.id = id;
    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;
    this.completed_onboarding_at = completed_onboarding_at;
  }

  /**
   * Checks if user is subscribed to pro
   * @returns true if user is subscribed to pro
   */
  isSubscribedToPro() {
    return (
      this.membership_tier === "premium" ||
      this.membership_tier === "premium_gold"
    );
  }

  /**
   * Checks if user is subscribed to pro individual stripe
   * @returns true if user is subscribed to pro individual stripe
   */
  isSubscribedToProIndividualStripe() {
    return this.stripe_subscription_plans?.personal_plan.is_active;
  }

  /**
   * Get the supercreator membership type
   * @returns A string representing the supercreator membership type
   */
  supercreatorMembershipType() {
    switch (this.membership_tier) {
      case "none":
        return "none";
      case "premium":
        return "silver";
      case "premium_gold":
        return "gold";
      default:
        return "none";
    }
  }
}
