/**
 * Abilities Reducer
 */
//action types
import {
  ADD_SUPERSCRIPT,
  GET_SUPERSCRIPTS,
  GET_FEATURED_SCRIPTS,
  SET_ACTIVE_SUPERSCRIPT,
  SET_ACTIVE_SCRIPT_RECIPE,
  RESET_ACTIVE_SCRIPT_RECIPE,
  SHOW_NEED_PERMISSION_FOR_SCRIPT,
  UPDATE_SUPERSCRIPT,
  IS_SAVING_SCRIPT,
  UPDATE_SUPERSCRIPT_LOCALLY,
  ADD_SCENE,
  UPDATE_SCENE,
  DELETE_SCENE,
  ERROR_FETCH_SUPERSCRIPT,
  FETCH_SUPERSCRIPT,
  UPDATE_SCENES,
  FETCH_MY_SUPERSCRIPTS,
  ERROR_FETCHING_ASSETS,
  SET_ACTIVE_SCENE,
  RESET_ACTIVE_SCRIPT,
  SHOULD_FETCH_SUPERSCRIPT,
  UPDATE_SCRIPT_EDITOR_SETTINGS,
  GENERATE_NEXT_SCENE_FOR_SCRIPT,
  REPLACE_SUPERSCRIPT,
  DELETE_SUPERSCRIPT,
  REPLACE_SCENE,
  FETCH_SCENES,
  SHOW_SHARE_MODAL,
  APPEND_TEXT_TO_SCRIPT_RESULT,
  PUSH_SCRIPT_TO_TOP_LATEST_SCRIPTS,
  LOGOUT_USER,
  UNDO_TEXT_TO_SCRIPT_RESULT,
  FETCHING_USER_HOME_FOLDERS,
  FETCHED_USER_HOME_FOLDERS,
  MOVE_SCRIPT_TO_FOLDER,
  SAVE_EXPANSION_STATE_FOR_FOLDER,
  ON_FOLDER_CREATED,
  ON_FOLDER_DELETED,
  ON_FOLDER_UPDATED,
  ON_SCRIPT_PERMISSIONS_SETTINGS_UPDATED,
} from "actions/Types";
import { ScriptRecipe, Superscript } from "kre8tv/model";
import { findIndex } from "lodash";
import { NotificationManager } from "react-notifications";

const LS_SUPERSCRIPTS_KEY = "superscripts";
const LS_FEATURED_SCRIPTS_KEY = "featured_scripts";
const LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY = "last_worked_on_superscripts";
const LS_FOLDER_PROPERTIES_KEY = "folder_properties";
const LS_SCRIPT_EDITOR_SETTINGS = "script_editor_settings";

const parseCached = () => {
  try {
    let cached = JSON.parse(
      localStorage.getItem(LS_SUPERSCRIPTS_KEY) ?? "[]"
    )?.map((o) => {
      // console.log(o);
      return new Superscript(o);
    });
    return cached.filter((script) => script.is_deleted !== true);
  } catch (error) {
    return [];
  }
};

/**
 * Parses the latest superscripts worked on from local storage.
 * @returns {Superscript[]} An array of Superscript objects.
 */
const parseLatestSuperscriptsWorkedOn = () => {
  try {
    let cached = JSON.parse(
      localStorage.getItem(LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY) ?? "[]"
    )?.map((o) => {
      // console.log("parseLatestSuperscriptsWorkedOn", o);
      return new Superscript(o);
    });
    // console.log("parseLatestSuperscriptsWorkedOn results", cached);
    let filtered = cached.filter((script) => script.is_deleted !== true);
    // console.log("parseLatestSuperscriptsWorkedOn final", filtered);
    return filtered;
  } catch (error) {
    return [];
  }
};

const parseScriptEditorSettings = () => {
  try {
    let cached = JSON.parse(
      localStorage.getItem(LS_SCRIPT_EDITOR_SETTINGS) ?? "{}"
    );
    return cached;
  } catch (error) {
    return {};
  }
};

const dummyRecipe = new ScriptRecipe({
  id: "dN2C2MdoEK2KfmxShPOj",
  goal_type: "create_script_from_slices",
});

const INIT_STATE = {
  superscripts: parseCached(),
  featuredScripts: JSON.parse(
    localStorage.getItem(LS_FEATURED_SCRIPTS_KEY) ?? "[]"
  )?.map((o) => {
    // console.log(o);
    return new Superscript(o);
  }),
  activeScriptRecipe: null,
  activeScript: null,
  activeScenes: [],
  activeScene: null,
  isSavingScript: false,
  isFetchingScript: false,
  isFetchingScriptId: null,
  isFetchingScenes: false,
  scriptStatus: "idle",
  lastUpdatedScripts: new Date().getTime(),
  lastUpdatedScript: new Date().getTime(),
  lastUpdatedScenes: new Date().getTime(),
  lastUpdatedActiveScene: new Date().getTime(),
  lastUpdatedSceneId: null,
  isFetchingScripts: false,
  isShowingShareScriptModal: false,
  lastSuperscriptsWorkedOn: parseLatestSuperscriptsWorkedOn(),
  textToScriptSubmissions: [],
  isFetchingUserHomeFolders: false,
  fetchScriptWithId: null,
  scriptEditorSettings: parseScriptEditorSettings(),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  // console.log("SuperscriptsReducer called", action);
  switch (action.type) {
    case FETCH_MY_SUPERSCRIPTS:
      return { ...state, isFetchingScripts: true };
    case GET_SUPERSCRIPTS:
      //save to local store
      localStorage.setItem(LS_SUPERSCRIPTS_KEY, JSON.stringify(action.payload));
      return {
        ...state,
        superscripts: action.payload,
        isFetchingScripts: false,
      };
    case GET_FEATURED_SCRIPTS:
      //save to local store
      localStorage.setItem(
        LS_FEATURED_SCRIPTS_KEY,
        JSON.stringify(action.payload)
      );
      return { ...state, featuredScripts: action.payload };
    case FETCH_SUPERSCRIPT:
      return {
        ...state,
        isFetchingScript: true,
        isFetchingScriptId: action.payload,
        activeScript: null,
        activeScenes: [],
        activeScene: null,
      };
    case ADD_SUPERSCRIPT: {
      let existing = state.superscripts;
      //TODO: Implement
      return {
        ...state,
        superscripts: existing,
      };
    }
    case DELETE_SUPERSCRIPT: {
      let removeItem = action.payload;
      let scriptId = removeItem.id;
      // console.log("DELETE_SUPERSCRIPT", scriptId);
      //new - we must also check in last worked on and userHomeFolders
      // console.log(
      //   "DELETE_SUPERSCRIPT - state.lastSuperscriptsWorkedOn size before",
      //   state.lastSuperscriptsWorkedOn.length
      // );
      let lastWorkedOn = state.lastSuperscriptsWorkedOn.filter(
        (script) => script.id !== scriptId
      );
      // console.log(
      //   "DELETE_SUPERSCRIPT - state.lastSuperscriptsWorkedOn size after",
      //   lastWorkedOn.length
      // );
      localStorage.setItem(
        LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY,
        JSON.stringify(lastWorkedOn)
      );

      let userHomeFolders = state.userHomeFolders;
      for (let i = 0; i < userHomeFolders.length; i++) {
        let folderToScripts = userHomeFolders[i];
        let folder = folderToScripts.folder;
        let scripts = folderToScripts.scripts;
        let newScripts = scripts.filter((script) => script.id !== scriptId);
        if (newScripts.length !== scripts.length) {
          folderToScripts.scripts = newScripts;
          userHomeFolders[i] = folderToScripts;
          break;
        }
      }

      //old - remove from superscripts
      let existing = state.superscripts.filter(
        (script) => script.id !== removeItem.id
      );

      //update local storage
      localStorage.setItem(LS_SUPERSCRIPTS_KEY, JSON.stringify(existing));

      const removeFromActive = state.activeScript?.id === removeItem.id;
      return {
        ...state,
        superscripts: existing,
        lastSuperscriptsWorkedOn: [...lastWorkedOn],
        activeScript: removeFromActive ? null : state.activeScript,
        activeScenes: removeFromActive ? [] : state.activeScenes,
        activeScene: removeFromActive ? null : state.activeScene,
        userHomeFolders: userHomeFolders,
      };
    }
    case RESET_ACTIVE_SCRIPT_RECIPE: {
      return {
        ...state,
        activeScriptRecipe: null,
        showNeedPermissionForScriptId: null,
      };
    }
    case SHOW_NEED_PERMISSION_FOR_SCRIPT: {
      const scriptId = action.payload;
      return {
        ...state,
        showNeedPermissionForScriptId: scriptId,
      };
    }
    case SET_ACTIVE_SCRIPT_RECIPE: {
      const activeScriptRecipe = action.payload;
      return {
        ...state,
        showNeedPermissionForScriptId: null,
        activeScriptRecipe: activeScriptRecipe,
      };
    }
    case SET_ACTIVE_SUPERSCRIPT: {
      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(
        action.payload
      );

      // const newFolders = updateScriptInFoldersToScripts(
      //   state.userHomeFolders,
      //   action.payload
      // );

      return {
        ...state,
        showNeedPermissionForScriptId: null,
        activeScenes: action.payload.scenes,
        activeScript: action.payload,
        isFetchingScript: false,
        lastUpdatedScript: new Date().getTime(),
        textToScriptSubmissions: [], //reset text to script submissions
        activeScriptRecipe: null,
        isFetchingScriptId: null,
        // userHomeFolders: newFolders,
        // lastSuperscriptsWorkedOn: latestSuperscriptsWorkedOn,
      };
    }
    case RESET_ACTIVE_SCRIPT:
      return {
        ...state,
        activeScenes: [],
        activeScript: null,
        activeScene: null,
        lastUpdatedScript: new Date().getTime(),
        isFetchingScript: false,
        isFetchingScriptId: null,
        showNeedPermissionForScriptId: null,
        fetchScriptWithId: null,
      };
    case ERROR_FETCH_SUPERSCRIPT:
      return { ...state, activeScript: null, isFetchingScript: false };
    case UPDATE_SUPERSCRIPT: {
      //1. update the superscript in our local array so it will update for the side bar
      const script = action.payload;
      const newFolders = updateScriptInFoldersToScripts(
        state.userHomeFolders,
        script
      );

      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(script);

      return {
        ...state,
        scriptStatus: "idle",
        isSavingScript: false,
        activeScript: script,
        lastUpdatedScript: new Date().getTime(),
        userHomeFolders: newFolders,
        lastSuperscriptsWorkedOn: latestSuperscriptsWorkedOn,
      };
    }
    case UPDATE_SUPERSCRIPT_LOCALLY: {
      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(
        action.payload
      );
      return {
        ...state,
        activeScript: action.payload,
        lastUpdatedScript: new Date().getTime(),
      };
    }
    case IS_SAVING_SCRIPT:
      return {
        ...state,
        scriptStatus: "saving",
        isSavingScript: true,
      };
    case FETCH_SCENES: {
      return {
        ...state,
        isFetchingScenes: true,
      };
    }
    case ADD_SCENE: {
      let scene = action.payload;
      let script = state.activeScript;
      let scenes = script.scenes;
      scenes.push(scene);
      script.scenes = scenes;
      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(script);
      return {
        ...state,
        activeScript: script,
        activeScenes: scenes,
        activeScene: scene,
        lastUpdatedScenes: new Date().getTime(),
        lastUpdatedSceneId: scene.id,
      };
    }
    case UPDATE_SCENE: {
      let scene = action.payload;
      let script = state.activeScript;
      let scenes = script.scenes;
      if (scenes.indexOf(scene) >= 0) {
        let index = scenes.indexOf(scene);
        scenes[index] = scene;
        script.scenes = scenes;
      } else {
        scenes[scene.scene_index] = scene;
        script.scenes = scenes;
      }
      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(script);
      return {
        ...state,
        activeScript: script,
        activeScenes: scenes,
        lastUpdatedSceneId: scene.id,
        lastUpdatedScenes: new Date().getTime(),
      };
    }
    case REPLACE_SCENE: {
      let scene = action.payload;
      let script = state.activeScript;
      let scenes = script.scenes;
      const scene_id = scene.id;
      //find scene in question
      const find_scene = scenes.filter((sc) => sc.id === scene_id);
      if (find_scene.length !== 1) {
        //abort
        return { ...state };
      }

      const index = scenes.indexOf(find_scene[0]);
      scenes[index] = scene;

      return {
        ...state,
        activeScenes: scenes,
        lastUpdatedSceneId: scene.id,
        lastUpdatedScenes: new Date().getTime(),
      };
    }
    case UPDATE_SCENES: {
      let scenes = action.payload.scenes;
      let script = action.payload.script;
      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(script);
      return {
        ...state,
        isFetchingScenes: false,
        activeScript: script,
        activeScenes: scenes,
        lastUpdatedScenes: new Date().getTime(),
      };
    }
    case DELETE_SCENE: {
      let scene = action.payload;
      let script = state.activeScript;
      let scenes = script.scenes;
      scenes = scenes.filter((sc) => sc.id !== scene.id);
      scenes = scenes.map((sc, index) => {
        sc.scene_index = index;
        return sc;
      });
      script.scenes = scenes;
      const latestSuperscriptsWorkedOn = addSuperscriptToLastWorkedOn(script);
      return {
        ...state,
        activeScript: script,
        activeScenes: scenes,
        lastUpdatedScenes: new Date().getTime(),
      };
    }
    case ERROR_FETCHING_ASSETS: {
      let error = action.payload;
      NotificationManager.success(error);
      break;
    }
    case SET_ACTIVE_SCENE: {
      let scene = action.payload;
      return {
        ...state,
        activeScene: scene,
        lastUpdatedActiveScene: new Date().getTime(),
      };
    }
    case GENERATE_NEXT_SCENE_FOR_SCRIPT: {
      let script = action.payload;
      script.isGeneratingNextScene = true;
      return {
        ...state,
        activeScript: script,
        lastUpdatedScript: new Date().getTime(),
      };
    }
    case REPLACE_SUPERSCRIPT: {
      let script = action.payload;
      //find the index, and replace in the array of superscripts

      let existingScripts = state.superscripts;

      // Find item index using _.findIndex (thanks @AJ Richardson for comment)
      var index = findIndex(existingScripts, { id: script.id });

      if (index >= 0) {
        existingScripts.splice(index, 1, script);

        return {
          ...state,
          superscripts: existingScripts,
          lastUpdatedScripts: new Date().getTime(),
        };
      }
      return { ...state };
    }
    case SHOW_SHARE_MODAL: {
      return {
        ...state,
        isShowingShareScriptModal: action.payload,
      };
    }
    case PUSH_SCRIPT_TO_TOP_LATEST_SCRIPTS: {
      let script = action.payload;
      let lastSuperscriptsWorkedOn = state.lastSuperscriptsWorkedOn;
      let index = lastSuperscriptsWorkedOn.findIndex((s) => s.id === script.id);
      // console.log("updating latest superscripts worked on from index", index);

      if (index >= 0) {
        lastSuperscriptsWorkedOn.splice(index, 1);
      }
      if (!script.folder_id) {
        lastSuperscriptsWorkedOn.unshift(script);
        saveLastWorkedOnSuperscriptsToLocalStorage(lastSuperscriptsWorkedOn);
      }

      return {
        ...state,
        lastSuperscriptsWorkedOn: lastSuperscriptsWorkedOn,
        lastUpdatedScripts: new Date().getTime(),
      };
    }
    case APPEND_TEXT_TO_SCRIPT_RESULT: {
      let payload = action.payload;
      let prompt = payload.prompt;
      let originalScript = payload.originalScript;
      let resultScript = payload.resultScript;
      let textToScriptSubmissions = state.textToScriptSubmissions;
      textToScriptSubmissions.push({ prompt, originalScript, resultScript });
      return {
        ...state,
        textToScriptSubmissions,
        activeScript: resultScript,
        activeScenes: resultScript.scenes,
        lastUpdatedScenes: new Date().getTime(),
        lastUpdatedScript: new Date().getTime(),
      };
    }
    case UNDO_TEXT_TO_SCRIPT_RESULT: {
      let script = action.payload;
      return {
        ...state,
        activeScript: script,
        activeScenes: script.scenes,
        lastUpdatedScenes: new Date().getTime(),
        lastUpdatedScript: new Date().getTime(),
      };
    }
    case FETCHING_USER_HOME_FOLDERS: {
      return {
        ...state,
        isFetchingUserHomeFolders: true,
      };
    }
    case FETCHED_USER_HOME_FOLDERS: {
      let folders = action.payload;
      const without_folders = folders?.without_folders ?? [];
      //iterate over every script and add it to the latest superscripts worked on, where we can sort via last updated
      let latestSuperscriptsWorkedOn = [];
      without_folders.forEach((script) => {
        latestSuperscriptsWorkedOn.push(script);
      });
      saveLastWorkedOnSuperscriptsToLocalStorage(latestSuperscriptsWorkedOn);

      //for each folder, let's just add the is_expanded property based on the folder id and our local storage
      const with_folders = folders?.with_folders ?? [];
      let foldersWithIsExpanded = with_folders.map((folderToScripts) => {
        let folder = folderToScripts.folder;
        let folderId = folder.id;
        let isExpanded = getIsFolderExpanded(folderId);
        folderToScripts.folder.is_expanded = isExpanded;
        return folderToScripts;
      });

      return {
        ...state,
        lastSuperscriptsWorkedOn: latestSuperscriptsWorkedOn,
        isFetchingUserHomeFolders: false,
        userHomeFolders: foldersWithIsExpanded,
      };
    }
    case MOVE_SCRIPT_TO_FOLDER: {
      let payload = action.payload;
      let script = payload.script;
      let folder = payload.folder;
      let atIndex = payload.atIndex;
      // console.log(
      //   "superscripts reducer - move script to folder w/ payload",
      //   payload
      // );
      let foldersToScripts = state.userHomeFolders;
      let latestSuperscriptsWorkedOn = state.lastSuperscriptsWorkedOn;
      //1. if the script has a folder_id - remove it and update the folder
      if (script.folder_id) {
        //find the folder
        let folderIndex = foldersToScripts.findIndex(
          (f) => f.folder.id === script.folder_id
        );
        //if we found the folder, check if the script is in it
        if (folderIndex >= 0) {
          let folder = foldersToScripts[folderIndex];
          let scripts = folder.scripts;
          let scriptIndex = scripts.findIndex((s) => s.id === script.id);
          //if we found the script, remove it from the folder
          if (scriptIndex >= 0) {
            scripts.splice(scriptIndex, 1);
            foldersToScripts[folderIndex].scripts = scripts;
          }
        }
      } else {
        // no folder id - remove from last worked on (no folder id)
        // console.log(`script ${script.id} does not have a folder id`);
        //remove from last worked on
        let scriptIndex = latestSuperscriptsWorkedOn.findIndex(
          (s) => s.id === script.id
        );
        if (scriptIndex >= 0) {
          latestSuperscriptsWorkedOn.splice(scriptIndex, 1);
          saveLastWorkedOnSuperscriptsToLocalStorage(
            latestSuperscriptsWorkedOn
          );
        }
      }

      //update the script
      script.folder_id = folder?.id ?? null;

      //2. if folder is not null, add the script to the folder (otheriwse, add to last worked on)
      if (folder) {
        let folderIndex = foldersToScripts.findIndex(
          (f) => f.folder.id === folder.id
        );
        if (folderIndex >= 0) {
          let folder = foldersToScripts[folderIndex];
          let scripts = folder.scripts;
          scripts.splice(atIndex, 0, script);
          foldersToScripts[folderIndex].scripts = scripts;
        }
      } else {
        //add to last worked on (no folder scripts)
        latestSuperscriptsWorkedOn.splice(atIndex, 0, script);
        saveLastWorkedOnSuperscriptsToLocalStorage(latestSuperscriptsWorkedOn);
      }

      return {
        ...state,
        userHomeFolders: [...foldersToScripts],
        lastSuperscriptsWorkedOn: [...latestSuperscriptsWorkedOn],
      };
    }
    case SAVE_EXPANSION_STATE_FOR_FOLDER: {
      let folder = action.payload.folder;
      let folderId = folder.id;
      let expanded = action.payload.expanded ?? false;
      //find the folder in the user home folders
      let foldersToScripts = state.userHomeFolders;
      let folderIndex = foldersToScripts.findIndex(
        (f) => f.folder.id === folderId
      );
      if (folderIndex >= 0) {
        foldersToScripts[folderIndex].folder.is_expanded = expanded;
      }
      saveIsFolderExpanded(folderId, expanded);
      return { ...state, userHomeFolders: [...foldersToScripts] };
    }
    case ON_FOLDER_CREATED: {
      let folder = action.payload;
      let foldersToScripts = state.userHomeFolders;
      //insert at front position
      foldersToScripts.splice(0, 0, { folder: folder, scripts: [] });
      return { ...state, userHomeFolders: [...foldersToScripts] };
    }
    case ON_FOLDER_DELETED: {
      let folder = action.payload;
      let foldersToScripts = state.userHomeFolders;
      let folderIndex = foldersToScripts.findIndex(
        (f) => f.folder.id === folder.id
      );
      if (folderIndex >= 0) {
        foldersToScripts.splice(folderIndex, 1);
      }
      return { ...state, userHomeFolders: [...foldersToScripts] };
    }
    case ON_FOLDER_UPDATED: {
      let folder = action.payload;
      let foldersToScripts = state.userHomeFolders;
      let folderIndex = foldersToScripts.findIndex(
        (f) => f.folder.id === folder.id
      );
      if (folderIndex >= 0) {
        foldersToScripts[folderIndex].folder = folder;
      }
      return { ...state, userHomeFolders: [...foldersToScripts] };
    }
    case ON_SCRIPT_PERMISSIONS_SETTINGS_UPDATED: {
      let script = action.payload.script;
      let permissionSettings = action.payload.permissionSettings;
      const activeScript = state.activeScript;
      if (activeScript && activeScript.id === script.id) {
        activeScript.permission_settings = permissionSettings;
        return { ...state, activeScript: activeScript };
      }
      break;
    }
    case SHOULD_FETCH_SUPERSCRIPT: {
      let scriptId = action.payload;
      return { ...state, fetchScriptWithId: scriptId };
    }
    case UPDATE_SCRIPT_EDITOR_SETTINGS:
      const { scriptId, settings } = action.payload;
      //get existing scriptEditorSettings
      const existingScriptEditorSettings = state.scriptEditorSettings;
      //update the settings for the scriptId
      existingScriptEditorSettings[scriptId] = settings;
      // console.log(`saving script editor settings for ${scriptId} - `, settings);
      //save to local storage
      localStorage.setItem(
        LS_SCRIPT_EDITOR_SETTINGS,
        JSON.stringify(existingScriptEditorSettings)
      );
      return {
        ...state,
        scriptEditorSettings: existingScriptEditorSettings,
      };
    case LOGOUT_USER:
      //remove all scripts from local storage
      localStorage.removeItem(LS_SUPERSCRIPTS_KEY);
      localStorage.removeItem(LS_FEATURED_SCRIPTS_KEY);
      localStorage.removeItem(LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY);
      return { ...INIT_STATE };
    default:
      return state;
  }
};

const addSuperscriptToLastWorkedOn = (script) => {
  try {
    let cached = JSON.parse(
      localStorage.getItem(LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY) ?? "[]"
    );

    //check - don't add local scripts if it is local only (not saved to server)
    if (script.is_local === true) {
      return cached;
    }

    let existing = cached.filter((o) => o.id !== script.id);

    //check - don't add if it has a folder id
    if (script.folder_id) {
      return existing;
    }

    existing.unshift(script);
    localStorage.setItem(
      LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY,
      JSON.stringify(existing)
    );
    // console.log(
    //   "parseLatestSuperscriptsWorkedOn -added to last worked on scriptId",
    //   script.id
    // );
    return existing;
  } catch (error) {
    console.error("parseLatestSuperscriptsWorkedOn error - ", error);
    return [];
  }
};

const updateScriptInFoldersToScripts = (folders, script) => {
  try {
    let foldersToScripts = folders;
    let folderIndex = foldersToScripts.findIndex(
      (f) => f.folder.id === script.folder_id
    );
    if (folderIndex >= 0) {
      let folder = foldersToScripts[folderIndex];
      let scripts = folder.scripts;
      let scriptIndex = scripts.findIndex((s) => s.id === script.id);
      if (scriptIndex >= 0) {
        scripts[scriptIndex] = script;
        foldersToScripts[folderIndex].scripts = scripts;
      }
    }
    return foldersToScripts;
  } catch (error) {
    console.error("updateScriptInFoldersToScripts error - ", error);
    return folders;
  }
};

const saveLastWorkedOnSuperscriptsToLocalStorage = (scripts) => {
  try {
    localStorage.setItem(
      LS_LAST_WORKED_ON_SUPERSCRIPTS_KEY,
      JSON.stringify(scripts)
    );
  } catch (error) {
    console.error("saveLastWorkedOnSuperscriptsToLocalStorage error - ", error);
  }
};

//Folder Properties

const getIsFolderExpanded = (folderId) => {
  return getFolderPropertyFromLocalStorage(folderId, "is_expanded");
};

const saveIsFolderExpanded = (folderId, isExpanded) => {
  saveFolderPropertyToLocalStorage(folderId, "is_expanded", isExpanded);
};

const saveFolderPropertyToLocalStorage = (folderId, property, value) => {
  try {
    let cached = JSON.parse(
      localStorage.getItem(LS_FOLDER_PROPERTIES_KEY) ?? "[]"
    );
    let existing = cached.filter((o) => o.folder.id !== folderId);
    let folder = cached.find((o) => o.folder.id === folderId);
    if (folder) {
      folder[property] = value;
      existing.push(folder);
    } else {
      existing.push({ folder: { id: folderId }, [property]: value });
    }
    localStorage.setItem(LS_FOLDER_PROPERTIES_KEY, JSON.stringify(existing));
  } catch (error) {
    console.error("saveFolderPropertyToLocalStorage error - ", error);
  }
};

const getFolderPropertyFromLocalStorage = (folderId, property) => {
  try {
    let cached = JSON.parse(
      localStorage.getItem(LS_FOLDER_PROPERTIES_KEY) ?? "[]"
    );
    let folder = cached.find((o) => o.folder.id === folderId);
    if (folder) {
      return folder[property];
    }
  } catch (error) {
    console.error("getFolderPropertyFromLocalStorage error - ", error);
  }
  return null;
};
