import React from "react";
import { makeStyles, mergeClasses } from "@mui/styles";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  textfieldSpecial: {
    fontSize: "24px",
    fontWeight: "500",
  },
}));

const SCTextField = ({ className, ...props }) => {
  const classes = useStyles();

  // console.log("className", className);

  return (
    <TextField
      className={`${classes.textfieldSpecial} ${className}`}
      {...props}
    >
      {props.children}
    </TextField>
  );
};

export default SCTextField;
