/**
 * Abilities Reducer
 */
//action types
import {
  ADD_ASSET_SEARCH_TO_HISTORY,
  UPDATE_ASSET_SEARCH_HISTORY_RESULT_EXPANSION,
  UPDATE_REMOTE_TEXT_TO_IMAGE_MODELS,
  ADD_AI_RESULT_TO_HISTORY,
  ON_AI_ASSETS_RESULT_FINISHED,
  ASSET_UPLOAD_DID_START,
  ASSET_UPLOAD_DID_FINISH,
  ASSET_UPLOAD_DID_FAIL,
  ASSET_UPLOAD_DID_UPDATE_PROGRESS,
  ASSET_UPLOAD_REMOVE_FROM_QUEUE,
  ADD_ASSET_TO_USER_UPLOADED_ASSETS,
  USER_UPLOADED_ASSETS_FETCHED,
} from "actions/Types";
import { ContentAsset } from "kre8tv/model";

const loadRecentAssetSearches = () => {
  return JSON.parse(localStorage.getItem("recent_asset_searches") ?? "[]").map(
    (o) => {
      // console.log(o);
      const title = o.title;
      const id = o.id;
      const expanded = o.expanded ?? false;
      const assets = o.assets.map((a) => {
        return new ContentAsset(o);
      });
      return { id: id, title, assets, expanded };
    }
  );
};

let testUploads = [
  {
    name: "1313.jpg",
    progress: 33,
    status: "error",
    size_in_bytes: 1500,
    file_type: "image",
  },
  {
    name: "dog_man.jpg",
    progress: 75,
    status: "uploading",
    size_in_bytes: 45000,
    file_type: "image",
  },
  {
    name: "cat_man.png",
    progress: 99,
    status: "uploading",
    size_in_bytes: 2345000,
    file_type: "image",
  },
];

const saveRecentAssetSearches = (recent_asset_searches) => {
  //make sure its not more than 10 items
  recent_asset_searches = recent_asset_searches.slice(0, 10);
  // localStorage.setItem(
  //   "recent_asset_searches",
  //   JSON.stringify(recent_asset_searches)
  // );
};

const INITIAL_STATE = {
  recent_asset_searches: loadRecentAssetSearches(),
  last_updated: new Date().getTime(),
  remote_text_to_image_models: [],
  ai_creation_results: [],
  user_uploaded_assets: [],
  last_updated_uploads: new Date().getTime(),
  uploads: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ASSET_SEARCH_TO_HISTORY: {
      //save to local store
      const payload = action.payload;
      const id = payload.id;
      const title = payload.title;
      const assets = payload.assets;
      const expanded = payload.expanded ?? false;
      const recent_asset_searches = state.recent_asset_searches;
      //push to top of array
      recent_asset_searches.unshift({ id, title, assets, expanded });
      saveRecentAssetSearches(recent_asset_searches);
      return { ...state, recent_asset_searches };
    }
    case UPDATE_ASSET_SEARCH_HISTORY_RESULT_EXPANSION: {
      const payload = action.payload;
      const id = payload.id;
      const expanded = payload.expanded;
      const recent_asset_searches = state.recent_asset_searches;
      const index = recent_asset_searches.findIndex((o) => o.id === id);
      if (index > -1) {
        recent_asset_searches[index].expanded = expanded;
      }
      saveRecentAssetSearches(recent_asset_searches);
      return {
        ...state,
        recent_asset_searches,
        last_updated: new Date().getTime(),
      };
    }
    case UPDATE_REMOTE_TEXT_TO_IMAGE_MODELS: {
      const payload = action.payload;
      return {
        ...state,
        remote_text_to_image_models: payload,
        last_updated: new Date().getTime(),
      };
    }
    case ADD_AI_RESULT_TO_HISTORY: {
      const payload = action.payload;
      const ai_creation_results = state.ai_creation_results;
      ai_creation_results.unshift(payload);
      return {
        ...state,
        ai_creation_results,
        last_updated: new Date().getTime(),
      };
    }
    case ON_AI_ASSETS_RESULT_FINISHED: {
      //clear any sections that have showLoading = true
      const ai_creation_results = state.ai_creation_results;
      const new_ai_creation_results = ai_creation_results.filter(
        (o) => o.showLoading !== true
      );
      return {
        ...state,
        ai_creation_results: new_ai_creation_results,
        last_updated: new Date().getTime(),
      };
    }
    case ASSET_UPLOAD_DID_START: {
      const payload = action.payload;
      const uploads = state.uploads;
      uploads.unshift(payload);
      return {
        ...state,
        uploads,
        last_updated_uploads: new Date().getTime(),
      };
    }
    case ASSET_UPLOAD_DID_FINISH: {
      const payload = action.payload;
      const uploads = state.uploads;
      const index = uploads.findIndex((o) => o.id === payload.id);
      if (index > -1) {
        uploads[index] = payload;
      }
      return {
        ...state,
        uploads,
        last_updated_uploads: new Date().getTime(),
      };
    }
    case ASSET_UPLOAD_DID_FAIL: {
      const payload = action.payload;
      const uploads = state.uploads;
      const index = uploads.findIndex((o) => o.id === payload.id);
      if (index > -1) {
        uploads[index] = payload;
      }
      return {
        ...state,
        uploads,
        last_updated_uploads: new Date().getTime(),
      };
    }
    case ASSET_UPLOAD_DID_UPDATE_PROGRESS: {
      const payload = action.payload;
      const uploads = state.uploads;
      const index = uploads.findIndex((o) => o.id === payload.id);
      if (index > -1) {
        uploads[index] = payload;
      }
      return {
        ...state,
        uploads,
        last_updated_uploads: new Date().getTime(),
      };
    }
    case ASSET_UPLOAD_REMOVE_FROM_QUEUE: {
      const payload = action.payload;
      const uploads = state.uploads;
      const index = uploads.findIndex((o) => o.id === payload.id);

      if (index > -1) {
        uploads.splice(index, 1);
      }
      return {
        ...state,
        uploads,
        last_updated_uploads: new Date().getTime(),
      };
    }
    case ADD_ASSET_TO_USER_UPLOADED_ASSETS: {
      const payload = action.payload;
      const user_uploaded_assets = state.user_uploaded_assets;
      user_uploaded_assets.unshift(payload);
      return {
        ...state,
        user_uploaded_assets,
        last_updated: new Date().getTime(),
      };
    }
    case USER_UPLOADED_ASSETS_FETCHED: {
      const payload = action.payload;
      const user_uploaded_assets = payload;
      return {
        ...state,
        user_uploaded_assets,
        last_updated: new Date().getTime(),
      };
    }

    default:
      return { ...state };
  }
};
