export class TextToImageModel {
  id: string = "";
  name: number = 0;
  image_url: string = "";
  description: string = "";

  constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.image_url = obj.image_url;
    this.description = obj.description;
  }

  static fromJSON(json: any) {
    if (!json || !json.id) throw new Error("Invalid JSON for TextToImageModel");
    return new TextToImageModel(json);
  }
}
